import React from "react"

const Footer = ({ className }) => (
  <footer className={`container-fluid text-center py-5 ${className || "mt-auto"}`}>
    <a href="https://www.locale.to" className="mx-auto text-muted">
      © {new Date().getFullYear()} - Locale
    </a>
  </footer>
)

export default Footer
