import React from "react"
import { Link } from "gatsby-plugin-intl"
import Img from "../img/logo.png"
import classNames from "classnames"
import { path } from "../config/routes"

const Logo = props => {
  const className = classNames("logo", props.className)
  return (
    <Link to={path("ROOT")}>
      <img src={Img} className={className} alt="Locale" />
    </Link>
  )
}

export default Logo
