import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Row, Col, Button } from "react-bootstrap"
import { redirect } from "../config/routes"

const BackToDashboardButton = () => (
  <Row className="justify-content-left">
    <Col>
      <Button size="sm" className="btn-white" onClick={() => redirect("DASHBOARD")}>
        <FormattedMessage
          id="components.backToDashboard.Button"
          defaultMessage="Go back to your dashboard"
        />
      </Button>
    </Col>
  </Row>
)

export default BackToDashboardButton
