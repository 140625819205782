import * as Cookies from "js-cookie";

export default class HybridStorage {
  constructor(data) {
    this.domain = data.domain;
    this.expires = data.expires;
    this.path = data.path;
    this.sameSite = data.sameSite;
    this.secure = data.secure;
  }

  clear() {
    Object.keys(Cookies.get()).forEach(key => this.removeItem(key));
    window.localStorage.clear();
    window.sessionStorage.clear();
    return {};
  }

  getItem(key) {
    return this.isCookieKey(key)
      ? Cookies.get(key)
      : window.localStorage[key];
  }

  removeItem(key) {
    return this.isCookieKey(key)
      ? Cookies.remove(key, this.cookiesOptions())
      : delete window.localStorage[key];
  }

  setItem(key, value) {
    this.isCookieKey(key)
      ? Cookies.set(key, value, this.cookiesOptions())
      : window.localStorage.setItem(key, value);
    return this.getItem(key);
  }

  // PRIVATE METHODS
  cookiesOptions() {
    return {
      domain: this.domain,
      expires: this.expires,
      path: this.path,
      sameSite: this.sameSite,
      secure: this.secure,
    };
  }

  isCookieKey(key) {
    return key.endsWith("accessToken")
      || key.endsWith("idToken")
      || key.endsWith("LastAuthUser");
  }
}
