import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "./Footer"
import "../../scss/theme.scss"
import BackToDashboardButton from "../BackToDashboardButton"
import Logo from "../Logo"
import Toast from "../../components/Toast"

const CenteredLayout = props => {
  const toast = useLocation().state?.toast
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (toast) {
      setShow(true)
    }
  }, [])

  return (
    <>
      <div className="d-flex align-items-center border-top border-top-2 border-primary">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6} xl={4} className="mt-5 text-center">
              <Logo className="w-25 mb-3" />
            </Col>
          </Row>
          {props.isNewProject && <BackToDashboardButton />} 
          {toast && (
            <Toast
              delay={toast.delay}
              show={show}
              setShow={setShow}
              type={toast.type}
            >
              <p className="font-normal ml-3 mb-0">{toast.message}</p>
            </Toast>
          )}
          <Row className="justify-content-center">
            <Col xs={12} md={9} lg={8} className="text-center mb-5">
              {props.children}
            </Col>
          </Row>
          <Footer />
        </Container>
      </div>
    </>
  )
}

export default CenteredLayout
