import { ORG_TYPES } from "../models/organization"

export const getWorkspaceTypeFromPath = (path) => {
  if (!path || !isPartnerSubdomain(path)) {
    return ORG_TYPES.locale;
  }
  return path.split(".").shift();
};

export const vendorHasSubdomain = id => (id && id.indexOf("-") === -1 && id !== ORG_TYPES.locale);

export const replaceLocationFromWorkspaceType = (type, location) => {
  const hostSplit = location.host.split(".")
  const subdomain = vendorHasSubdomain(type) ? type : "app"
  const hostname = [subdomain, ...hostSplit.slice(1)].join(".")
  return `${location.protocol}//${hostname}${location.pathname}${location.search}`
}

export const isPartnerSubdomain = host => !(/^app\./.test(host))
